<template>
    <section class="list container">
        <div id="results">
            <div class="preheader d-flex justify-content-between" v-if="title">
                <h2>{{ title }}</h2>
                <ToggleArrow v-if="toggle == true" @click="toggleMe = !toggleMe" section="toggleMe" :status="toggleMe">
                </ToggleArrow>
            </div>
            <div v-if="toggleMe && !!items && items.length === 0" style="margin-left: 50px">
                <header v-if="toggleMe" class="d-flex justify-content-between flex-wrap flex-sm-nowrap">
                    <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
                        {{ noRecordsMessage }}
                    </div>
                    <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
                        <button class="btn btn-primary flex-0 d-block w-100-sd" v-if="addItems.display"
                            @click="triggerAction(addItems.action)">
                            {{ addItems.text }}
                        </button>
                    </div>
                </header>
            </div>
            <div v-if="!!items && items.length > 0">
                <header v-if="toggleMe" class="d-flex justify-content-between flex-wrap flex-sm-nowrap">
                    <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
                        <span v-if="!!csvUrl && csvUrl.length > 0">
                            <a class="btn btn-dark flex-0 mr-2"
                                @click.prevent="downloadReport(csvUrl, reportName, 'csv')">{{
                                    i18n['data-table-members'].tcExportCSV
                                }}</a>
                            <a class="btn btn-dark flex-0 mr-2"
                                @click.prevent="downloadReport(pdfUrl, reportName, 'pdf')">{{
                                    i18n['data-table-members'].tcExportPDF
                                }}</a>
                        </span>
                        <b-form-input v-model="filter" @input="handleSearchTermChange"
                            :placeholder="i18n['data-table-members'].tcSearch"
                            class="search w-100-sd mw-100-sd mb-3 mb-sm-0 d-block w-100-sd order-1 order-sm-2"></b-form-input>
                    </div>
                    <div class="d-flex align-items-center flex-wrap flex-sm-nowrap w-100-sd">
                        <button class="btn btn-primary flex-0 d-block w-100-sd" v-if="addItems.display"
                            @click="triggerAction(addItems.action)">
                            {{ addItems.text }}
                        </button>
                    </div>
                </header>
                <main v-if="toggleMe" class="table-responsive">
                    <b-table class="g-table" :fields="fields" :items="items" :per-page="perPage" :current-page="currentPage"
                        :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" sort-icon-left small>
                        <template #cell(spouse)="row">
                            <span class="arrow-down-icon" v-if="row.item.wife != null && !row.detailsShowing"
                                @click="row.toggleDetails">
                                <img src="../assets/svgs/arrow-down.svg" />
                            </span>
                            <span class="arrow-down-icon" v-if="row.item.wife != null && row.detailsShowing"
                                @click="row.toggleDetails">
                                <img src="../assets/svgs/arrow-up.svg" />
                            </span>
                        </template>
                        <template #row-details="row">
                            <b-card>
                                <b-row class="mb-2">
                                    <b-col sm="3" class="text-sm-right"><b>
                                            <h1>Wife</h1>
                                        </b></b-col>
                                </b-row>
                                <b-row class="mb-2">
                                    <b-col sm="3" class="text-sm-right"><b>Name:</b></b-col>
                                    <b-col>{{ row.item.wife.cad_name }}</b-col>
                                </b-row>

                                <b-row class="mb-2">
                                    <b-col sm="3" class="text-sm-right"><b>Email Address:</b></b-col>
                                    <b-col>{{ row.item.wife.eml_address }}</b-col>
                                </b-row>

                                <b-button size="small" @click="viewCandidate(row.item.wife)">View More Details</b-button>
                            </b-card>
                        </template>
                        <template v-slot:cell(name)="data">
                            <a href="#" style="underline" @click.prevent="individualStoreKeyAndRedirect(data)">{{ data.value
                            }}</a>
                        </template>
                        <template v-slot:cell(sort_name)="data">
                            <a href="#" style="underline" @click.prevent="individualStoreKeyAndRedirect(data)">{{
                                data.item.name
                            }}</a>
                        </template>
                        <template v-slot:cell(yes)="data">
                            <a href="#" @click="handleApproveCandidate(data.item)">
                                <b-icon icon="hand-thumbs-up" variant="dark" scale="2"></b-icon>
                            </a></template>
                        <template v-slot:cell(no)="data">
                            <a href="#" @click="handleDenyCandidate(data.item)">
                                <b-icon style="margin-right: 20px" icon="trash2-fill" scale="2"></b-icon>
                            </a>
                        </template>
                        <template v-slot:cell(actions)="data">
                            <a href="#" @click="viewCandidate(data.item)">
                                <b-icon style="" icon="file-earmark-fill" scale="2"></b-icon>
                            </a>
                        </template>
                    </b-table>
                </main>
                <footer v-if="toggleMe" class="d-flex align-items-center justify-content-between">
                    <div class="prev" @click="prevPage" :class="{ hidden: this.currentPage == 1 }">
                        <i-arrow-left />
                    </div>
                    <nav class="d-flex align-items-center justify-content-center">
                        <div class="show d-flex align-items-center justify-content-end">
                            <div class="lower">{{ i18n['data-table-members'].tcShow }}</div>
                            <button :class="{ active: this.perPage == 20 }" @click="updateNumber(20)">20</button>
                            <button :class="{ active: this.perPage == 50 }" @click="updateNumber(50)">50</button>
                            <button :class="{ active: this.perPage == 80 }" @click="updateNumber(80)">80</button>
                        </div>
                        <div class="sep"></div>
                        <div class="pagination justify-self">
                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                                :hide-goto-end-buttons="true" @change="pageChanged"></b-pagination>
                        </div>
                    </nav>
                    <div class="next" v-if="!!items" @click="nextPage" :class="{
                        hidden: Math.ceil(this.items.length / this.perPage) == this.currentPage,
                    }">
                        <i-arrow-right />
                    </div>
                </footer>
            </div>
        </div>
    </section>
</template>
<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import iArrowLeft from '@/assets/svgs/arrow-left.vue'
import iArrowRight from '@/assets/svgs/arrow-right.vue'
import { reportDownloadMixin } from '@/mixins/reportDownloadMixin'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'

export default {
    name: 'data-table-members',
    mixins: [reportDownloadMixin],
    props: {
        i18n: {
            type: Object,
            default: () => {
                return {
                    'data-table-members': {
                        tcExportCSV: 'Export XLS',
                        tcExportPDF: 'Export PDF',
                        tcFilter: 'Filter',
                        tcSearch: 'Search',
                        tcShow: 'Show',
                    },
                }
            },
        },
        fields: {
            type: Array,
            default: [],
        },
        items: {
            type: Array,
            default: [],
        },
        csvUrl: {
            type: String,
            default: '',
        },
        pdfUrl: {
            type: String,
            default: '',
        },
        reportName: {
            type: String,
            default: 'Report',
        },
        addItems: {
            type: Object,
            default: () => ({
                text: 'Add',
                display: false,
                action: '#',
            }),
        },
        cmp_key: {
            type: String,
            default: '',
        },
        title: String,
        toggle: Boolean,
        campType: {
            type: String,
            default: '',
        },
        noRecordsMessage: {
            type: String,
            default: 'No records were found.',
        },
        individualType: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            // Member Table Settings
            sortBy: 'Name',
            sortDesc: false,
            perPage: 20,
            currentPage: 1,
            filter: null,
            number: 20,
            pag: '',
            toggleMe: true,
        }
    },
    methods: {
        ...mapActions({
            loadIndividualProfile: 'membership/loadIndividualProfile',
            setLoadingStatus: 'menu/setLoadingStatus',
            setSearchTerm: 'search/setSearchTerm',
            setSelectedIndividualKey: 'user/setSelectedIndividualKey',
        }),
        pageChanged() {
            document.getElementById('results').scrollIntoView()
        },
        updateNumber(num) {
            this.perPage = num
        },
        prevPage() {
            if (this.currentPage == 1) {
                return
            }
            this.currentPage--
            document.getElementById('results').scrollIntoView()
        },
        nextPage() {
            if (!this.items) {
                return 1
            }
            if (Math.ceil(this.items.length / this.perPage) == this.currentPage) {
                return
            }
            this.currentPage++
            document.getElementById('results').scrollIntoView()
        },
        triggerAction(action) {
            this.$emit(action)
        },
        handleApproveCandidate(candidate) {
            this.$emit('handleApproveCandidate', candidate)
        },
        handleDenyCandidate(candidate) {
            this.$emit('handleDenyCandidate', candidate)
        },
        async handleSearchTermChange() {
            await this.setSearchTerm({ membersTerm: this.filter })
        },
        viewCandidate(candidate) {
            this.$emit('handleViewAction', candidate)
        },
        log(item) { },
        type(val) {
            return typeof val
        },
        notUndefined(val) {
            return !val ? '' : val
        },
        async individualStoreKeyAndRedirect(data) {
            await this.setSelectedIndividualKey(data.item.ind_key)
            switch (this.individualType) {
                case 'member':
                    this.$router.push('/programs/mb/member-profile/')
                    break
                case 'prospect':
                    this.$router.push('/programs/mb/prospect-profile/')
                    break
                case 'nonmember':
                    this.$router.push('/programs/mb/non-member-profile/')
                    break
                default:
                    this.$router.push('/programs/mb/membership')
                    break
            }
        },
        async individualStoreKeyAndRedirectDues(data) {
            this.setSelectedIndividualKey(data.item.ind_key)
            try {
                await Promise.all([this.setLoadingStatus(true), this.loadIndividualProfile()]).then(() => {
                    this.$router.push('/programs/mb/member-dues/')
                })
            } catch (e) {
                console.error(e)
            } finally {
                this.setLoadingStatus(false)
            }
        },
        formatDate(data) {
            let returnValue = ''
            if (!!data.item.add_date) {
                returnValue = data.item.add_date.substring(0, 10)
            }
            if (!!data.item.join_date) {
                returnValue = data.item.join_date.substring(0, 10)
            }
            if (!!data.item.start_date) {
                returnValue = data.item.start_date.substring(0, 10)
            }
            return returnValue
        },
    },
    computed: {
        ...mapGetters({
            searchTerms: 'search/searchTerms',
        }),
        rows() {
            return !!this.items ? this.items.length : 0
        },
    },
    created() {
        this.filter = this.searchTerms.membersTerm
    },
    components: {
        iArrowLeft: iArrowLeft,
        iArrowRight: iArrowRight,
        ToggleArrow: ToggleArrow,
    },
}
</script>
  
<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';

.preheader {
    padding: 30px 32px 15px;
    align-items: center;

    @include breakpoint(sm) {
        padding-left: 23px;
        padding-right: 23px;
    }

    a {
        cursor: pointer;
    }

    h2 {
        margin: 0;
        padding: 0;
        color: #000;
        font-family: $DIN;
        font-size: 42px;
        letter-spacing: 1.4;
        line-height: 44px;
        text-transform: uppercase;

        @include breakpoint(sm) {
            padding-top: 0;
        }
    }
}

.lower {
    text-transform: lowercase;
}</style>
  